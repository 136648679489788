import { Accordion, Card, Tab, Tabs, Button } from 'react-bootstrap'
import InfoIcon from '@mui/icons-material/AnnouncementRounded'
import CopyContentIcon from '@mui/icons-material/ContentCopyRounded'
import { isDemo, isIframe } from '../../utils/CloudDetails'
import CliHintContainer from './CliHintContainer'
import { getBrokerApiKey, getBrokerUrl } from '../../utils/broker'
import { ConnectionState } from '../../services/LicenseService'
import { ProductAnalyticsTracker } from '../../utils/ProductAnalytics'
import {
    cliCommand,
    cliGenerateScriptCommand,
    createProtopieConfigCommand,
    protopieCommand,
    protopieRunConfigCommand,
    runScriptCommand,
    curlCommand,
} from './CliCommands'
import { pythonSubscribeCommand, pythonSubscribeDemoCommand } from './PythonCommands'

interface ExampleCodeProps {
    productAnalyticsTracker: ProductAnalyticsTracker
    connectionState: ConnectionState
    latestCliVersion: string | undefined
    signalsToUseInExamples: Array<{ namespace: string; signals: Array<string> }>
}

export default function ExampleCodeCard(props: ExampleCodeProps) {
    const latestVersionInfo = () => {
        if (props.connectionState.clientHasInternet) {
            return <>Latest version is recommended. The latest version is {props.latestCliVersion}.</>
        } else {
            return (
                <>
                    Latest version is recommended, an internet connection is required to obtain information about the
                    latest version.
                </>
            )
        }
    }

    const codeExamples = () => {
        const brokerUrl = getBrokerUrl()
        const brokerApiKey = getBrokerApiKey()

        return (
            <>
                <div>
                    <div className="rounded p-3 remotive-primary-10-background">
                        <p className="remotive-font-md mb-2">
                            We have a samples repository availabe in Github for anyone who wants to run code against
                            this recording. With the samples you will be able to modify the code and play around with
                            the signals in this recording. Our repositories have detailed READMEs to make it easier to
                            follow along.
                        </p>
                        <p className="mb-3 remotive-font-md lexend-bold">
                            Simply check out the instructions down below to get going!
                        </p>
                        <p className="text-secondary remotive-font-sm mb-0">
                            For issues with any of the steps below please use our community discussions on github,{' '}
                            <a href="https://github.com/remotivelabs/remotivelabs-community/discussions">
                                github.com/remotivelabs/remotivelabs-community/discussions
                            </a>{' '}
                            or send an email to <a href="mailto:support@remotivelabs.com">support@remotivelabs.com</a>
                        </p>
                    </div>

                    <br />
                </div>
                <Tabs defaultActiveKey="python" className="mb-3">
                    <Tab tabClassName='remotive-dark-color' eventKey="curl" title="curl">
                        <p className="small mx-2  remotive-font-md">
                            You can use curl to subscribe to signals and much more.
                            <br />
                            Here is a curl sample that you can copy paste and try out
                        </p>
                        <CliHintContainer
                            hints={[
                                {
                                    title: 'Subscribe to selected signals',
                                    subtitle: 'This command will subscribe to signals selected and print to console',
                                    command: `${curlCommand(brokerUrl, props.signalsToUseInExamples, brokerApiKey)}`,
                                }
                            ]}
                        />
                    </Tab>
                    <Tab tabClassName='remotive-dark-color' eventKey="python" title="Python">
                        <p className="small mx-2 remotive-font-md">
                            If you clone our remotive-samples github repository you can try out some of our samples.
                            Here we showcase one simple sample that subscribes to the broker - just copy/paste and run.
                        </p>
                        <p className="mx-2  remotive-font-sm" style={{ fontFamily: 'monospace' }}>
                            $ git clone https://github.com/remotivelabs/remotivelabs-samples
                            <Button
                                className="remotive-btn-sm remotive-neutral-10-background border-0"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        'git clone https://github.com/remotivelabs/remotivelabs-samples'
                                    )
                                }
                            >
                                <CopyContentIcon sx={{ fontSize: '1.1em' }} className="remotive-btn-copy" />
                            </Button>
                            <br />$ cd remotivelabs-samples/python/subscribe
                            <Button
                                className="remotive-btn-sm remotive-neutral-10-background border-0"
                                onClick={() =>
                                    navigator.clipboard.writeText('cd remotivelabs-samples/python/subscribe')
                                }
                            >
                                <CopyContentIcon sx={{ fontSize: '1.1em' }} className="remotive-btn-copy" />
                            </Button>
                            <br />$ pip3 install -r requirements.txt
                            <Button
                                className="remotive-btn-sm remotive-neutral-10-background border-0"
                                onClick={() => navigator.clipboard.writeText('pip3 install -r requirements.txt')}
                            >
                                <CopyContentIcon sx={{ fontSize: '1.1em' }} className="remotive-btn-copy" />
                            </Button>
                            <br />$ {pythonSubscribeCommand(brokerUrl, props.signalsToUseInExamples, brokerApiKey)}
                            <Button
                                className="remotive-btn-sm remotive-neutral-10-background border-0"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        pythonSubscribeCommand(brokerUrl, props.signalsToUseInExamples, brokerApiKey)
                                    )
                                }
                            >
                                <CopyContentIcon sx={{ fontSize: '1.1em' }} className="remotive-btn-copy" />
                            </Button>
                        </p>
                    </Tab>
                    <Tab tabClassName='remotive-dark-color' eventKey="c++" title="C++">
                        <p className="small mx-2  remotive-font-sm" style={{ fontFamily: 'monospace' }}>
                            $ git clone https://github.com/remotivelabs/remotivelabs-samples
                            <br />
                            <span className="lexend-bold">
                                Follow the instructions at
                                https://github.com/remotivelabs/remotivelabs-samples/tree/main/cpp
                            </span>
                        </p>
                    </Tab>

                    <Tab tabClassName='remotive-dark-color' eventKey="AAOS" title="AAOS (Android)">
                        <p className="small mx-2  remotive-font-sm" style={{ fontFamily: 'monospace' }}>
                            $ git clone https://github.com/remotivelabs/remotivelabs-android-emulator.git
                            <br />
                            <span className="lexend-bold">
                                Follow the instructions at https://github.com/remotivelabs/remotivelabs-android-emulator
                            </span>
                        </p>
                    </Tab>
                    <Tab tabClassName='remotive-dark-color' eventKey="protopie" title="Protopie">
                        <p className="small mx-2  remotive-font-md">
                            We use the RemotiveCLI to manage the protopie connect bridge, installation instructions can
                            be found{' '}
                            <a href={'https://docs.remotivelabs.com/docs/remotive-cli/installation'} target={'_blank'}>
                                here
                            </a>
                            . {latestVersionInfo()}
                            <br />
                            You will need a ProtoPie Connect license in order to use this feature. It is expected that
                            you have knowledge in ProtoPie + Connect in order to use this.
                        </p>
                        <CliHintContainer
                            hints={[
                                {
                                    title: 'Connect to ProtoPie connect with selected signals',
                                    subtitle:
                                        'This command will subscribe to signals selected and publish these to ProtoPie Connect',
                                    command: `${protopieCommand(
                                        false,
                                        brokerUrl,
                                        props.signalsToUseInExamples,
                                        brokerApiKey
                                    )}`,
                                },
                                {
                                    title: 'Same as above but performs a minor modification to the signal names',
                                    subtitle:
                                        'This command replaces all . (dots) -> _ (underscore) and lowercases before sending to Connect',
                                    command: `${protopieCommand(
                                        true,
                                        brokerUrl,
                                        props.signalsToUseInExamples,
                                        brokerApiKey
                                    )}`,
                                },
                                {
                                    title: 'You can also create a config file with the subscription that can be shared with your team',
                                    subtitle: 'This command creates the configuration file based on selected signals',
                                    command: `${createProtopieConfigCommand(props.signalsToUseInExamples)}`,
                                },
                                {
                                    title: 'This command connects to ProtoPie connect with the configuration created above',
                                    command: `${protopieRunConfigCommand(brokerUrl, brokerApiKey)}`,
                                },
                            ]}
                        />
                    </Tab>
                    <Tab tabClassName='remotive-dark-color' eventKey="cli" title="CLI">
                        <p className="small mx-2  remotive-font-md">
                            You can use RemotiveCLI to subscribe to signals and much more, read installation
                            instructions at our{' '}
                            <a href={'https://docs.remotivelabs.com/docs/remotive-cli/installation'} target={'_blank'}>
                                CLI docs
                            </a>
                            . {latestVersionInfo()}
                            <br />
                            Here are some CLI samples that you can copy paste and try out
                        </p>
                        <CliHintContainer
                            hints={[
                                {
                                    title: 'Subscribe to selected signals',
                                    subtitle: 'This command will subscribe to signals selected and print to console',
                                    command: `${cliCommand(brokerUrl, props.signalsToUseInExamples, brokerApiKey)}`,
                                },
                                {
                                    title: 'Generate Lua script template ',
                                    subtitle:
                                        'Based on your selected signals this generates a LUA script template that can be used' +
                                        'to map or create synthetic signals',
                                    command: `${cliGenerateScriptCommand(props.signalsToUseInExamples)}`,
                                },
                                {
                                    title: 'Subscribe with LUA script',
                                    subtitle:
                                        'This command uses the LUA script you created above and subscribe to those signals',
                                    command: `${runScriptCommand(brokerUrl, brokerApiKey)}`,
                                },
                            ]}
                        />
                    </Tab>
                    {isDemo() && (
                        <Tab eventKey="custom_signal_mapping" title="Scripted Signals">
                            <p className="mx-2 remotive-font-md">
                                Scripted signals is a feature that allows you to perform computations on a stream of
                                signals using your own Lua code while the data is being streamed. To learn more, please
                                check out the{' '}
                                <a
                                    href="https://github.com/remotivelabs/remotivelabs-samples/tree/main/python/subscribe-to-scripted-signal"
                                    target="_blank"
                                >
                                    scripted signal samples
                                </a>{' '}
                                on GitHub and{' '}
                                <a href="https://docs.remotivelabs.com/docs/broker/scripted_signals" target="_blank">
                                    our documentation
                                </a>
                                .
                                <br />
                                <br />
                                For a quick start, follow the steps below:
                            </p>
                            <p className="small mx-2" style={{ fontFamily: 'monospace' }}>
                                $ git clone https://github.com/remotivelabs/remotivelabs-samples
                                <br />
                                $ cd remotivelabs-samples/python/subscribe-to-scripted-signal
                                <br />
                                $ pip3 install -r requirements.txt
                                <br />$ {pythonSubscribeDemoCommand(brokerUrl, brokerApiKey)}
                            </p>
                        </Tab>
                    )}
                </Tabs>
            </>
        )
    }

    return (
        <Card className="shadow-sm border-0 text-start mb-4 mt-2 rounded-4">
            <Card.Body className="rounded p-0 d-flex flex-column remotive-white-background rounded-4">
                <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                    <Accordion
                        flush
                        defaultActiveKey={isIframe() ? '-1' : '0'}
                        className="mx-1 p-1 w-100 flex-truncate remotive-white-background rounded-4"
                    >
                        <Accordion.Item className="mx-0 px-0 rounded-4 flex-truncate" eventKey="0">
                            <Accordion.Header
                                className="mx-2 p-0 flex-truncate remotive-white-background lexend-bold"
                                onClick={() =>
                                    props.productAnalyticsTracker.track('Expand/Condense example code section')
                                }
                            >
                                <InfoIcon sx={{ fontSize: 25 }} className="remotive-success-60-color me-2" />
                                Examples to subscribe to signals from an external application
                            </Accordion.Header>
                            <Accordion.Body className="mx-0 px-0" style={{ backgroundColor: 'white !important' }}>
                                {codeExamples()}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Card.Body>
        </Card>
    )
}
