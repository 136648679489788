// Remotive CLI commands
export const protopieCommand = (
    withExpression: boolean,
    brokerUrl: string,
    subscriptionExamples: Array<{ namespace: string; signals: Array<string> }>,
    brokerApiKey: string | undefined
) => {
    const cmd = 'remotive connect protopie '
    const args = subscriptionExamples
        .map((namespaceAndSignals) =>
            namespaceAndSignals.signals
                .map((signal) => ` --signal ${namespaceAndSignals.namespace}:${signal}`)
                .join(' ')
        )
        .join(' ')
    const expression = withExpression ? `--signal-name-expression 'replace(".","_").lower()'` : ''

    return cmd + args + ` --broker-url ${brokerUrl} --api-key ${brokerApiKey} ${expression}`
}

export const createProtopieConfigCommand = (
    subscriptionExamples: Array<{ namespace: string; signals: Array<string> }>
) => {
    const args = subscriptionExamples
        .map((namespaceAndSignals) =>
            namespaceAndSignals.signals
                .map((it) => {
                    return `"${it}": {"namespace": "${namespaceAndSignals.namespace}"}`
                })
                .join(', ')
        )
        .join(', ')
    const config = `{"subscription": {${args}}}`
    return `echo '${config}' > remotivelabs-protopie-config.json`
}

export const protopieRunConfigCommand = (brokerUrl: string, brokerApiKey: string | undefined) => {
    const cmd = 'remotive connect protopie --config remotivelabs-protopie-config.json '
    return cmd + ` --broker-url ${brokerUrl} --api-key ${brokerApiKey}`
}

export const curlCommand = (
    brokerUrl: string,
    subscriptionExamples: Array<{ namespace: string; signals: Array<string> }>,
    brokerApiKey: string | undefined
) => {
    const args = subscriptionExamples
        .map((namespaceAndSignals) =>
            namespaceAndSignals.signals
                .map((signal) => `{"name": "${signal}","namespace": {"name": "${namespaceAndSignals.namespace}"}}`)
                .join(', ')
        )
        .join(' ')

    const apiKeyOption = brokerApiKey === undefined ? '' : `-H 'x-api-key: ${brokerApiKey}'`
    const cmd = `curl -v -X POST -H 'Content-type: application/json' ${apiKeyOption} -d '{"clientId": {"id": "some_client_id"}, "signals": {"signalId": [${args}]}, "onChange": false}' ${brokerUrl}/v1/SubscribeToSignals`
    return cmd
}

export const cliCommand = (
    brokerUrl: string,
    subscriptionExamples: Array<{ namespace: string; signals: Array<string> }>,
    brokerApiKey: string | undefined
) => {
    const apiKeyOption = brokerApiKey === undefined ? '' : `--api-key ${brokerApiKey}`

    const cmd = `remotive broker signals subscribe --url ${brokerUrl} ${apiKeyOption}`

    const args = subscriptionExamples
        .map((namespaceAndSignals) =>
            namespaceAndSignals.signals
                .map((signal) => ` --signal ${namespaceAndSignals.namespace}:${signal}`)
                .join(' ')
        )
        .join(' ')

    return cmd + args
}

export const cliGenerateScriptCommand = (
    subscriptionExamples: Array<{ namespace: string; signals: Array<string> }>
) => {
    const cmd = `remotive broker scripting new-script`

    const args = subscriptionExamples
        .map(
            (namespaceAndSignals) =>
                namespaceAndSignals.signals
                    .map((signal) => ` --input-signal ${namespaceAndSignals.namespace}:${signal}`)
                    .join(' ') + ' --output-signal Vehicle.Speed'
        )
        .join(' ')
    return cmd + args
}

export const runScriptCommand = (brokerUrl: string, brokerApiKey: string | undefined) => {
    const apiKeyOption = brokerApiKey === undefined ? '' : `--api-key ${brokerApiKey}`

    return `remotive broker signals subscribe --script myscript.lua --url ${brokerUrl} ${apiKeyOption}`
}
